import React from "react";
import BaseLayout from "../components/baseLayout";
import HeroTitle from "../components/heroTitle";

export default function NotFoundPage() {
  return (
    <BaseLayout>
      <HeroTitle title="404" />
      <div className="container">
        <div className="section">
          <div className="subtitle">
            You just hit a route that doesn&#39;t exist... the sadness.
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
